export const RE_CAPTCHA_KEY = process.env.REACT_APP_RE_CAPTCHA_KEY;
export const PROJECT_PER_PAGE = 16;

export const APP_VERSION = 1.6;


const config = {
    BASE_DOMAIN: process.env.REACT_APP_BASE_DOMAIN,
    BASE_URL: process.env.REACT_APP_BASE_URL,
    APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    DEFAULT_SITE_URL: process.env.REACT_APP_DEFAULT_SITE_URL,
    SHOW_HIDDEN_FEATURES: process.env.REACT_APP_SHOW_HIDDEN_FEATURES,
    CDN_URL: process.env.REACT_APP_CDN_URL,
    NEW_PLAT: process.env.REACT_APP_NEW_PLAT,
    IS_REGISTRATION_ENABLED: process.env.REACT_APP_IS_REGISTRATION_ENABLED === 'true',
    NS_SERVER_1: process.env.REACT_APP_NS1,
    NS_SERVER_2: process.env.REACT_APP_NS2,
    IS_SSO_ENABLED: process.env.REACT_APP_IS_SSO_ENABLED === 'true',
    AUTH_IFRAME_URL: process.env.REACT_APP_AUTH_IFRAME_URL,
    PLATH_LOGIN_URL: process.env.REACT_APP_PLATH_LOGIN_URL

}

export default config;