export const RoutePath = {
    DEFAULT: '/',
    LOGIN: '/login',
    SET_PASSWORD: '/set-password',
    REGISTRATION: '/registration',
    ACCOUNT_VERIFICATION: '/account-verification',
    FORGOT_PASSWORD: '/forgot-password',
    SSO: '/sso',
    NOTFOUND: '/404',
    ABOUT_US: '/about-us',
    SITE_BUILDER: '/site-builder',

    CREATE_PROJECT: '/create-project',

    PROJECTS: '/projects',
    CREATE_PAGE: '/create-page',
    PROFILE: '/profile',

    // Roles
    ROLES: '/roles',
    ADD_ROLES: '/roles/add_role',
    EDIT_ROLE: '/roles/edit_role',
    VIEW_ROLE: '/roles/view_role',

    // Users
    USERS: '/users',
    ADD_USER: '/users/add_user',
    USER_DETAILS: '/users/user_details',
    EDIT_USER: '/users/edit_user',

    // Requests
    REQUESTS: '/requests',

    // Companies
    COMPANIES: '/companies',

    //Settings pages
    SETTINGS_OVERVIEW: '/edit/overview',
    SETTINGS_LANGUAGES: '/edit/languages',
    SETTINGS_CAPTCHA: '/edit/captcha',
    SETTINGS_CURRENCIES: '/edit/currencies',
    SETTINGS_CASINO: '/edit/casino',
    SETTINGS_DOMAINS: '/edit/domains',
    SETTINGS_DOMAINS_INFO: '/edit/domains/info',
    SETTINGS_BANNERS: '/edit/banners',
    SETTINGS_PROMOTIONS: '/edit/promotions',
    SETTINGS_ADD_DOMAIN: '/edit/domains/add',
    SETTINGS_PAYMENTS: '/edit/payments',
    SETTINGS_AGE_RESTRICTION: '/edit/agerestriction',
    SETTINGS_LICENSE: '/edit/license',
    SETTINGS_AFFILIATE: '/edit/affiliate',
    SETTINGS_REFERRAL_ID: '/edit/referral',
    SETTINGS_PRODUCTS: '/edit/products',

    SETTINGS_SEO: '/edit/seo/settings',
    SETTINGS_SEO_SOCIAL: '/edit/seo/seoSocial',
    SETTINGS_SEO_SCHEMA_MARKUP: '/edit/seo/schema_markup',
    SETTINGS_SEO_SITEMAP: '/edit/seo/sitemap',

    CUSTOMIZE: '/edit/customize',
    CUSTOMIZE_PAGES: '/edit/customize/pages',
    SETTINGS_LOGOS: '/edit/logos',
    THIRD_PARTY_INTEGRATIONS: '/edit/third_party',
    CUSTOM_SCRIPTS: '/edit/custom',

    ADD_ONS: '/edit/add_ons',
    DEV_TOOLS: '/edit/dev_tools'


};

export const RestPath = {
    // Account verification
    VERIFY_EMAIL: '/Account/VerifyEmail',
    RESEND_EMAIL_VERIFICATION: '/Account/ResendEmailVerification/',
    VERIFY_MOBILE: '/Account/VerifyMobile',
    RESEND_MOBILE_VERIFICATION: '/Account/ResendMobileVerification',
    GET_REGISTRATION_VERIFICATION: '/ProjectSettings/GetRegistrationVerification',
    SWITCH_REGISTRATION_VERIFICATION: '/ProjectSettings/SwitchRegistrationVerification',
    CHECK_HASH: '/Account/CheckHash',
    CHANGE_PASSWORD_BY_URL: '/Account/ChangePasswordByUrl',

    ONE_CLICK_REGISTRATION: '/BuilderAdmin/BrandSetting/OneClickRegistartion',

    // Two factor

    GENERATE_TFA_CODE: '/Account/GenerateTFACode',
    RECOVER_TFA: '/Account/RecoverTFA',

    //Profile
    SEND_VERIFYCATION_EMAIL: '/User/SendEmailVerification',
    VERIFY_CHANGED_EMAIL: '/User/VerifyEmail',
    SEND_VERIFYCATION_MOBILE: '/User/SendMobileVerification',
    VERIFY_CHANGED_MOBILE: '/User/VerifyMobile',
    AUTHORIZE_GET: '/Authorize/Get',

    CHANGE_PASSWORD: '/User/ChangePassword',
    CHANGE_MOBILE: '/User/ChangeMobile',
    CHANGE_FULLNAME: '/User/EditAccount',

    GET_USER_DATA: '/User/Get',

    // SETTINGS
    GET_PROJECT_ALL_COUNTRIES: '/ProjectSettings/GetCountries',
    GET_PROJECT_SETTINGS: '/ProjectSettings/GetSettings',
    GET_PROJECT_SETTINGS_INFO: '/ProjectSettings/GetAllSettings',

    GET_PROJECT_PAYMENT_SETTINGS: '/ProjectSettings/GetPaymentSettings',

    GET_PROJECT_CAPTCHA_PROVIDERS: '/ProjectSettings/GetCaptchaSettings',
    SET_PROJECT_CAPTCHA_PROVIDERS: '/ProjectSettings/SetCaptchaSettings',

    GET_GENERAL_SETTINGS: '/ProjectSettings/GetSettings',
    SET_GENERAL_SETTINGS: '/ProjectSettings/SetSettings',

    GET_PROJECT_CURRENCIES: '/ProjectSettings/GetCurrencies',
    ADD_CURRENCIES: '/ProjectSettings/AddCurrencies',
    CHANGE_DEFAULT_CURRENCIES: '/ProjectSettings/ChangeDefaultCurrency',
    DELETE_CURRENCIES: '/ProjectSettings/DeleteCurrency',

    GET_PROJECT_LANGUAGES: '/ProjectSettings/GetLanguages',
    ADD_LANGUAGES: '/ProjectSettings/AddLanguages',
    CHANGE_DEFAULT_LANGUAGES: '/ProjectSettings/ChangeDefaultLanguage',
    DELETE_LANGUAGES: '/ProjectSettings/DeleteLanguage',

    GET_DOMAINS: '/Domain/GetDomains',
    GET_DOMAIN_SETTINGS: '/Domain/GetDomainSettings',
    ADD_DOMAIN: '/Domain/AddDomain',
    REMOVE_DOMAIN: '/Domain/RemoveDomain',

    DOMAIN_ACTIVATION_CHECK: '/Domain/ActivationCheck',
    DOMAIN_CLEAR_CACHE: '/Domain/CleareChache',
    DOMAIN_ADD_REDIRECTION: '/Domain/AddRedirection',
    DOMAIN_EDIT_REDIRECTION: '/Domain/EditRedirection',
    DOMAIN_REMOVE_REDIRECTION: '/Domain/RemoveRedirection',
    DOMAIN_CHANGE: '/Domain/ChangeDomain',
    DOMAIN_ERRORS: '/Domain/DomainErrors',

    GET_LOGOS: '/ProjectSettings/GetLogoSettings',
    CHANGE_LOGO_SETTINGS: '/UICustomization/ChangeLogoSettings',
    GET_LOGO_SETTINGS: '/UICustomization/GetLogoSettings',
    SET_LOGOS: '/ProjectSettings/SetLogoInfo',
    UPLOAD_LOGOS: '/UICustomization/UploadImage',

    GET_ADD_ONS: '/Plugin/GetPlugins',
    SET_ADD_ON: '/Plugin/SetPlugin',

    GET_BANNERS: '/Banner/GetBanners',
    GET_BANNER_CATEGORIES_BY_SPORT_STATUS: '/Banner/BannerCategories',
    UPLOAD_BANNER: '/Banner/UploadBanner',
    GET_BANNERS_SECTIONS: '/Banner/GetSections',
    ADD_BANNER_SECTIONS: '/Banner/Section',
    CHANGE_BANNER_VISIBILTY: '/Banner/State',
    MOVE_BANNER: '/Banner/MoveBanner',
    DELETE_BANNER: '/Banner/DeleteBanner',
    EDIT_BANNER: '/Banner/EditBanner',
    CHECK_BANNER_NAME: '/Banner/CheckName',
    SET_SECTION: '/Banner/SetSection',
    GET_BANNER_GRID_LAYOUT: '/Banner/GetBannerGridLayout',
    UPDATE_BANNER_GRID_LAYOUT: '/Banner/UpdateBannerGridLayout',
    CHANGE_BANNER_SECTION_VISIBILITY: '/Banner/ChangeBannerSectionVisibility',
    // Custom Scripts
    GET_CUSTOM_SCRIPTS: '/ThirdPartyIntegration/GetCustomScripts',
    GET_CUSTOM_SCRIPT: '/ThirdPartyIntegration/GetCustomScript',
    ADD_CUSTOM_SCRIPT: '/ThirdPartyIntegration/AddCustomScript',
    EDIT_CUSTOM_SCRIPT: '/ThirdPartyIntegration/EditCustomScript',
    DELETE_CUSTOM_SCRIPT: '/ThirdPartyIntegration/DeleteCustomScript',
    CHANGE_CUSTOM_SCRIPT_STATE: '/ThirdPartyIntegration/ChangeCustomScriptState',

    GET_CUSTOM_STYLES: '/ThirdPartyIntegration/GetCustomStyles',
    GET_CUSTOM_STYLE: '/ThirdPartyIntegration/GetCustomStyle',
    ADD_CUSTOM_STYLE: '/ThirdPartyIntegration/AddCustomStyle',
    EDIT_CUSTOM_STYLE: '/ThirdPartyIntegration/EditCustomStyle',
    DELETE_CUSTOM_STYLE: '/ThirdPartyIntegration/DeleteCustomStyle',
    CHANGE_CUSTOM_STYLE_STATE: '/ThirdPartyIntegration/ChangeCustomStyleState',

    GET_EXTERNAL_SERVICES: '/ThirdPartyIntegration/GetExternalServices',
    GET_STANDART_EXTERNAL_SERVICE: '/ThirdPartyIntegration/GetExternalService',
    GET_CUSTOM_EXTERNAL_SERVICE: '/ThirdPartyIntegration/GetCustomExternalService',
    ADD_CUSTOM_EXTERNAL_SERVICE: '/ThirdPartyIntegration/AddCustomExternalService',
    EDIT_CUSTOM_EXTERNAL_SERVICE: '/ThirdPartyIntegration/EditCustomExternalService',
    EDIT_STANDART_EXTERNAL_SERVICE: '/ThirdPartyIntegration/EditExternalService',
    EDIT_CUSTOM_EXTERNAL_SERVICE_STATE: '/ThirdPartyIntegration/ChangeServiceState',
    ADD_STANDART_EXTERNAL_SERVICE: '/ThirdPartyIntegration/AddExternalService',
    DELETE_SERVICE: '/ThirdPartyIntegration/DeleteService',
    GET_PAYMENTS: '/PaymentSystem/Get',
    SHOW_ON_WEB: '/PaymentSystem/ShowOnWeb',

    // General settings
    GET_CLIENT_MIN_AGE: '/ProjectSettings/GetClientMinAge',
    SET_CLIENT_MIN_AGE: '/ProjectSettings/SetClientMinAge',
    GET_MARKETS: "/ProjectSettings/GetMarkets",
    SET_MARKET: "/ProjectSettings/SetMarket",

    //Referal Friend
    GET_REFERAL_FRIEND_SETTINGS: '/ProjectSettings/GetReferrerFriend',
    SET_REFERAL_FRIEND_SETTINGS: '/ProjectSettings/SetReferrerFriend',


    // SYSTEM SETTINGS
    GET_SYSTEM_SETTINGS: '/System/GetSystemSettings',
    GET_SYSTEM_LANGUAGES: '/System/GetLanguages',
    GET_SYSTEM_CURRENCIES: '/System/GetCurrencies',
    GET_SYSTEM_CAPTCHA_PROVIDERS: '/System/GetCaptchaProviders',
    GET_SYSTEM_PAYMENTS: '/System/GetPaymentSystems',
    GET_ALL_EXTERNAL_SERVICES: '/System/GetExternalServices',
    GET_PROJECT_LICENSES: '/System/GetLicenses',

    // Customization
    GET_CONTROLS: '/UICustomization/GetControls',
    SET_CONTROLS: '/UICustomization/SaveControl',
    GET_SECTIONS: '/UICustomization/GetSections',
    GET_MAIN_MENU: '/UICustomization/GetMainMenu',
    GET_FEATURED_MENU: '/UICustomization/FeaturedMenu',
    GET_FORMS_CONTROLS: '/UICustomization/GetFormsControl',
    GET_SECONDARY_MENU: '/UICustomization/GetSecondaryMenu',
    GET_FOOTER_MENU: '/UICustomization/GetFooterMenu',
    GET_MOBILENAVBAR_MENU: '/UICustomization/GetMobileNavBarMenu',
    GET_FLOATING_MENU: '/UICustomization/GetFloatingMenu',
    GET_DESKTOPNAVBAR_MENU: '/UICustomization/GetDesktopNavBarMenu',
    GET_UI_SETTINGS_DATA: '/ProjectUISettings/GetProjectUISetting',
    SET_UI_SETTINGS: '/ProjectUISettings/UpdateProjectUISettings',
    SET_COLOR_SETTINGS: '/ProjectUISettings/UpdateColors',
    SET_COLOR_MODE: '/ProjectUISettings/SetColorMode',
    GET_COLOR_MODE_SETTINGS: '/ProjectUISettings/GetColorModeSettings',
    SET_BUTTONS_SETTINGS: '/ProjectUISettings/UpdateButtons',
    SET_FORM_INPUTS_SETTINGS: '/ProjectUISettings/UpdateFormControls',
    SET_FONT_SETTINGS: '/ProjectUISettings/UpdateFont',
    GET_TEMPLATE_COLOR_SETS: '/ProjectUISettings/GetTemplateUISettings',
    GET_CONTROLS_TREE: '/UICustomization/GetControlTree',
    DELETE_CONTROL: '/UICustomization/DeleteCustomControl',
    DELETE_HEADER_CONTROL: '/UICustomization/DeleteHeaderControl',
    DELETE_FOOTER_CONTROL: '/UICustomization/DeleteFooterControl',
    DELETE_MOBILE_NAVBAR_CONTROL: '/UICustomization/DeleteMobileNavBarControl',
    DELETE_FLOATING_MENU_CONTROL: '/UICustomization/DeleteFloatingMenuControl',
    GET_MENU_SETTINGS: '/UICustomization/GetMenuSettings',
    GET_NAVBAR_MENU_SETTINGS: '/UICustomization/GetMobileNavBarMenuSettings',
    CHANGE_MENU_SETTINGS: '/UICustomization/ChangeMenuSettings',
    CHANGE_MENU_ADVANCED_SETTINGS: '/UICustomization/ChangeAdvancedMenuSettings',
    GET_ACCOUNT_MENU: '/UICustomization/GetAccountMenu',        
    GET_MOBILE_ACCOUNT_MENU: '/UICustomization/GetMobileRightMenu',        
    CHANGE_MENU_PIN_MODE: '/UICustomization/PinMenuItem',

    ADD_MENU_ITEM: '/UICustomization/AddMenuItem',
    EDIT_MENU_ITEM: '/UICustomization/EditMenuItem',
    MOVE_MENU_ITEM: '/UICustomization/MoveMenuItem',
    DELETE_MENU_ITEM: '/UICustomization/DeleteCustomControl',
    GET_SOCIAL_ITEMS: '/UICustomization/GetSocialMediaControls',
    EDIT_SOCIAL_ITEMS: '/UICustomization/EditSocialMediaControls',
    CHANGE_SOCIAL_MEDIA_CONTROL_VISIBILITY: '/UICustomization/ChangeSocialMediaControlVisibility',
    MOVE_SOCIAL_MEDIA_CONTROL: '/UICustomization/MoveSocialMediaControl',
    CHANGE_CONTROL_VISIBILITY: '/UICustomization/ChangeControlVisibility',
    CHANGE_FOOTER_MENU_CONTROL_VISIBILITY: '/UICustomization/ChangeFooterMenuControlVisibility',
    CHANGE_MOBILE_NAVBAR_CONTROL_VISIBILITY: '/UICustomization/ChangeMobileNavBarControlVisibility',
    CHANGE_FLOATING_MENU_CONTROL_VISIBILITY: '/UICustomization/ChangeFloatingMenuControlVisibility',
    CHANGE_DESKTOPNAVBAR_MENU_VISIBILITY: '/UICustomization/ChangeDesktopNavBarControlVisibility',
    CHANGE_HEADER_CONTROL_VISIBILITY: '/UICustomization/ChangeHeaderControlVisibility',
    CHANGE_FORM_CONTROL_VISIBILITY: '/UICustomization/ChangeFormControlVisibility',
    MOVE_CUSTOM_CONTROL: '/UICustomization/MoveCustomControl',
    GET_LOGIN_FORM_OPTIONS: '/ProjectSettings/GetLoginFormOption',
    SET_LOGIN_FORM_OPTIONS: '/ProjectSettings/SetLoginFormOption',
    GET_FORGOT_USERNAME_STATE: '/ProjectSettings/GetForgotUsernameState',
    SWITCH_FORGOT_USERNAME_STATE: '/ProjectSettings/SwitchForgotUsername',
    MOVE_DESKTOP_NAVBAR_ITEM:'/UICustomization/MoveSideBarMenu',

    //CustomUI
    CHANGE_CUSTOMER_SUPPORT_VISIBILITY: '/UICustomization/ChangeCustomerSupportVisibility',
    CHANGE_AGE_RESTRICTION_VISIBILITY: '/UICustomization/ChangeAgeRestrictionVisibility',
    CHANGE_COPYRIGHT_VISIBILITY: '/UICustomization/ChangeCopyrightVisibility',
    // CHANGE_POWERED_BY_DIGITAIN_VISIBILITY: '/UICustomization/ChangePoweredByDigitainVisibility',
    CHANGE_ABOUT_COMPANY_VISIBILITY: '/UICustomization/ChangeAboutCompanyVisibility',
    CHANGE_MOBILE_APPS_LINK_VISIBILITY: '/UICustomization/ChangeMobileAppsLinkVisibility',
    CHANGE_LANGUAGE_DROPDOWN_VISIBILITY: '/UICustomization/ChangeLanguageDropdownVisibility',
    EDIT_CUSTOMER_SUPPORT: '/UICustomization/EditCustomerSupport',
    EDIT_AGE_RESTRICTION: '/UICustomization/EditAgeRestriction',
    EDIT_COPYRIGHT_INFO: '/UICustomization/EditCopyRightInfo',
    // EDIT_POWERED_BY_DIGITAIN: '/UICustomization/EditPoweredByDigitainInfo',
    EDIT_ABOUT_COMPANY: '/UICustomization/EditAboutCompany',
    EDIT_MOBILE_APPS_LINK: '/UICustomization/EditMobileAppsLink',
    EDIT_LANGUAGE_DROPDOWN: '/UICustomization/EditLanguageDropdown',


    // Image blocks
    GET_IMAGE_BLOCK: '/UICustomization/GetImageBlockControls',
    ADD_IMAGE_BLOCK: '/UICustomization/AddImageBlockControls',
    EDIT_IMAGE_BLOCK: '/UICustomization/EditImageBlockControls',
    CHECK_BLOCK_IMAGE_NAME: '/UICustomization/CheckImageBlockName',
    CHANGE_IMAGE_BLOCKS_SECTION_VISIBILITY: '/UICustomization/ChangeImageBlockControlVisibility',
    MOVE_IMAGE_BLOCK: '/UICustomization/MoveImageBlockControl',
    EDIT_IMAGE_SECTION: '/UICustomization/EditImageBlock',
    DELETE_IMAGE_BLOCK: '/UICustomization/DeleteImageBlockControl',
    ADD_IMAGE_BLOCK_SECTION: '/UICustomization/AddImageBlockSection',
    EDIT_IMAGE_BLOCK_SECTION: '/UICustomization/EditImageBlockSection',
    DELETE_IMAGE_BLOCK_SECTION: '/UICustomization/DeleteImageBlockSection',

    //App Adviser
    GET_APP_ADVISER_SETTINGS: '/ProjectSettings/GetAppAdviserSettings',
    SET_APP_ADVISER_SETTINGS: '/ProjectSettings/SetAppAdviserSettings',

    //Mobile Apps
    GET_MOBILE_APPS_SETTINGS: '/ProjectSettings/GetMobileAppsSettings',
    SET_MOBILE_APPS_SETTINGS: '/ProjectSettings/SetMobileAppsUrls',
    SET_MOBILE_APPS_DEVICE_TYPE: '/ProjectSettings/SetMobileAppsDeviceType',

    // Payment controls
    GET_PAYMENT_CONTROLS: '/UICustomization/GetPaymentSystemControls',
    ADD_PAYMENT_CONTROLS: '/UICustomization/AddPaymentSystemControls',
    EDIT_PAYMENT_CONTROLS: '/UICustomization/EditPaymentSystemControl',
    GET_AVAILABLE_PAYMENTS: '/UICustomization/GetAvailablePaymentSystems',
    CHANGE_PAYMENT_CONTROL_VISIBILITY: '/UICustomization/ChangePaymentControlVisibility',
    MOVE_PAYMENT_CONTROL: '/UICustomization/MovePaymentControl',
    DELETE_PAYMENT_CONTROL: '/UICustomization/DeletePaymentControl',
    GET_PAYMENT_LOGO_SETTINGS: '/ProjectSettings/GetPaymentLogoSettings',
    SET_PAYMENT_LOGO_SETTINGS: '/ProjectSettings/SetPaymentLogoSettings',
    GET_PROVIDER_LOGO_SETTINGS: '/ProjectSettings/GetProviderLogoSettings',
    SET_PROVIDER_LOGO_SETTINGS: '/ProjectSettings/SetProviderLogoSettings',
    // License
    ADD_LICENSE_CONTROLS: '/ProjectSettings/AddLicense',
    DELETE_LICENSE: '/ProjectSettings/DeleteLicense',
    GET_LICENSE_SETTINGS: '/ProjectSettings/GetLicenses',

    // Icon Set
    GET_ICON_SET: '/ProjectUISettings/GetIconSet',
    SET_ICON_SET: '/ProjectUISettings/SetIconSet',
    GET_ICONS: '/Icon/GetIcons',

    //Popup Color Mode
    GET_POPUP_COLOR_MODE: '/ProjectUISettings/GetPopUpColorMode',
    SET_POPUP_COLOR_MODE: '/ProjectUISettings/SetPopUpColorMode',

    // Language Controls 
    GET_LANGUGE_CONTROLS: '/UICustomization/GetLanguageControls',
    EDIT_LANGUGE_CONTROL: '/UICustomization/EditLanguageControl',

    //Access Buttons
    GET_ACCESS_BUTTONS: '/ButtonSettings/GetAccessButtons',
    EDIT_ACCESS_BUTTONS: '/ButtonSettings/SetAccessButtons',

    //Providers
    GET_PROVIDRS_CONTROL: '/UICustomization/GetProviderControls',
    ADD_PROVIDER_CONTROL: '/UICustomization/AddProviderControls',
    GET_AVAILABLE_PROVIDERS: '/UICustomization/GetAvailableProviders',
    CHANGE_PROVIDER_CONTROL_VISIBILITY: '/UICustomization/ChangeProviderControlVisibility',
    MOVE_PROVIDER_CONTROL: '/UICustomization/MoveProviderControl',
    EDIT_PROVIDER_CONTROL: '/UICustomization/EditProviderControl',
    DELETE_PROVIDER_CONTROL: '/UICustomization/DeleteProviderControl',

    EDIT_CUSTOM_CONTROL: '/UICustomization/EditCustomControl',

    // Products
    ADD_SPORT: '/ProjectSettings/AddSport',
    REMOVE_SPORT: '/ProjectSettings/RemoveSport',
    CHANGE_SPORT_TYPE: '/ProjectSettings/ChangeSportSettings',
    GET_SPORT_PRODUCTS: '/ProjectSettings/GetSportProductsConfig',
    GET_CASINO_PRODUCTS: '/ProjectSettings/GetCasinoProductsConfig',
    CHANGE_CASINO_SETTINGS: '/ProjectSettings/ChangeCasinoJackpotWidgetSetting',
    GET_GAMES: '/ProjectProduct/Games',
    ADD_CASINO: '/ProjectProduct/AddCasino',
    REMOVE_CASINO: '/ProjectProduct/RemoveCasino',
    CHANGE_GAME_STATE: '/ProjectProduct/ChangeGameState',
    GET_ALL_PROVIDERS: '/ProjectProduct/Providers',
    GET_PROVIDERS: '/ProjectProduct/Providers',
    GET_CATEGORIES: '/ProjectProduct/Categories',
    GET_PRODUCTS: '/ProjectSettings/Products',

    //Featured Products
    GET_FEATURED_PRODUCTS: '/UICustomization/GetFeaturedProducts',
    GET_AVAILABLE_FEATURED_PRODUCTS: '/UICustomization/GetAvailableFeaturedProducts',
    ADD_FEATURED_PRODUCTS: '/UICustomization/AddFeaturedProductsControls',
    CHANGE_FEATURED_PRODUCT_CONTROL_VISIBILITY: '/UICustomization/ChangeFeaturedProductControlVisibility',
    MOVE_CONTROL: '/UICustomization/MoveControls',
    EDIT_FEATURED_PRODUCTS: '/UICustomization/EditFeaturedProducts',
    CHANGE_FEATURED_PRODUCTS: '/UICustomization/ChangeFeaturedProducts',
    EDIT_FEATURED_PRODUCT_INFO: '/UICustomization/EditFeaturedProductInfo',
    CHANGE_FEATURED_MENU: '/UICustomization/ChangeFeaturedMenuSettings',

    //Promotions
    GET_PROMOTIONS: '/Promotion/GetPromotions',
    ADD_PROMOTION: '/Promotion/AddPromotion',
    EDIT_PROMOTION: '/Promotion/EditPromotion',
    DELETE_PROMOTION: '/Promotion/DeletePromotion',
    MOVE_PROMOTION: '/Promotion/MovePromotion',
    CHANGE_PROMOTION_VISIBILITY: '/Promotion/ChangePromotionVisibility',
    ARCHIVE_PROMOTION: '/Promotion/ArchivePromotions',
    RESTORE_PROMOTION: '/Promotion/RestorePromotions',

    // Categories
    ADD_PROMOTION_CATEGORY: '/PromotionCategory/AddPromotionCategory',
    GET_PROMOTIONS_CATEGORIES: '/PromotionCategory/GetCategories',
    DELETE_PROMOTION_CATEGORY: '/PromotionCategory/DeletePromotionCategory',
    EDIT_PROMOTION_CATEGORY: '/PromotionCategory/EditPromotionCategory',
    GET_CATEGORIES_ICONS_VISIBILITY: '/PromotionCategory/GetIconsVisibility',
    CHANGE_CATEGORIES_ICONS_VISIBILITY: '/PromotionCategory/SwitchIcons',
    MOVE_PROMOTION_CATEGORY: '/PromotionCategory/MovePromotionCategory',
    GET_PROMOTION_CATEGORY_ICONS: '/Icon/GetIcons',
    CHANGE_PROMOTION_CATEGORY_VISIBILITY: '/PromotionCategory/ChangePromotionCategoryVisibility',
    CHECK_PROMOTION_CATEGORY_NAME: '/PromotionCategory/CheckName',

    //Registration Controls
    MOVE_FORM_CONTROL: '/UICustomization/MoveFormItem',
    EDIT_FORM_ITEM: '/UICustomization/EditFormItem',
    CHANGE_CONTROL_WIZARD_STATE: '/UICustomization/ChangeWizard',
    CHECK_COMPANY_NAME: '/Company/CheckCompanyName',

    //Timer Control
    GET_TIMER_CONTROL: '/UICustomization/GetTimerControl',
    EDIT_TIMER_CONTROL: '/UICustomization/EditTimerControl',

    //SEO
    GET_PROJECT_PAGES: "/Page/Get",
    GET_PAGE_SEO_SETTINGS: "/SEO/GetPageSettings",
    GET_RULES_SEO_SETTINGS: "/SEO/GetRulesSettings",
    GET_PROMOTION_CATEGORY_SEO_SETTINGS: "/SEO/GetPromotionCategorySettings",
    GET_PROMOTION_SEO_SETTINGS: "/SEO/GetPromotionSettings",
    SET_PAGE_SEO_SETTINGS: "/SEO/SetPageSettings",
    SET_PROMOTION_CATEGORY_SEO_SETTINGS: "/SEO/SetPromotionCategorySettings",
    SET_PROMOTION_SEO_SETTINGS: "/SEO/SetPromotionSettings",
    SET_SEO_SETTINGS: "/SEO/SetSettings",
    GET_SEO_PAGES: "/SEO/GetSEOSections",
    ADD_SEO_PAGE: "/Page/AddSEOPage",
    SET_SEO_SOCIAL_TAGS: "/SEO/SetSocialMediaTags",
    GET_SEO_SOCIAL_TAGS: "/SEO/GetSocialMediaTags",
    SET_SEO_SCHEMA_SETTINGS: "/SEO/SetSchema",
    GET_SEO_SCHEMA_SETTINGS: "/SEO/GetSchema",
    GET_SITEMAP_SETTINGS: "/SEO/GetSitemapSettings",
    SET_SITEMAP_SETTINGS: "/SEO/SetSitemapSettings",

    //Affiliate
    GET_AFFILIATE_SETTINGS: "/ProjectSettings/GetAffiliateConfig",
    SET_AFFILIATE_SETTINGS: "/ProjectSettings/SetAffiliateConfig",
    DELETE_AFFILIATE_SETTINGS: "/ProjectSettings/DeleteAffiliateConfig",

    //Location
    GET_LOCATION: '/ProjectSettings/GetCountryCode',

    //EDIT
    GET_NOT_CLASSIFIED_ITEMS: '/UICustomization/GetNotClassifiedControls',

    //Companies
    GET_ALL_USER_COMPANIES: '/Company/GetCompanies',
    GET_USER_COMPANIES: '/Project/GetCompanies',
    CREATE_COMPANY: '/Company/CreateCompany',
    DELETE_COMPANY: '/Company/DeleteCompany',
    CHANGE_COMPANY_NAME: '/Company/RenameCompany',

    //Roles
    GET_ROLES_LIST: '/Role/GetRoles',
    GET_ALL_ROLES: '/Role/GetAllRoles',
    GET_ROLE: '/Role/GetRole',
    GET_ROLE_PERMISSIONS: '/Role/GetPermissions',
    GET_USER_PERMISSIONS: '/Role/GetUserPermissions',
    CHECK_ROLE_NAME: '/Role/CheckRoleName',
    EDIT_ROLE: '/Role/EditRole',
    DELETE_ROLE: '/Role/DeleteRole',
    ADD_ROLE: '/Role/CreateRole',

    //Users
    GET_USERS: '/UserManagement/GetUsers',
    CREATE_USER: '/UserManagement/Create',
    EDIT_USER: '/UserManagement/Edit',
    GET_USER: '/UserManagement/GetUser',
    EDIT_USER_STATUS: '/UserManagement/EditStatus',

    //Requests
    GET_REQUESTS: '/Request/GetRequests',
    HANDLE_REQUEST: '/Request/HandleRequest',
    GET_REQUESTS_COUNT: '/Request/GetRequestsCount',

    //Pages
    GET_PAGES: '/Page/GetPages',
    GET_PAGE: '/Page/GetPage',
    CHECK_PAGE_NAME: '/Page/CheckPageName',
    CHECK_PAGE_ALIAS_NAME: '/Page/CheckPageAlias',
    UPLOAD_EDITOR_IMAGE: '/Editor/UploadEditorImage',
    EDIT_PAGE: '/Page/EditPage',
    DELETE_PAGE: '/Page/DeletePage',
    ADD_PAGE: '/Page/CreatePage',
    GET_PAGE_CONTENTS: '/Page/GetContents',
    GET_PAGE_CONTENT: '/Page/GetContent',
    SAVE_PAGE_AS_DRAFT: '/Page/SaveContentAsDraft',
    SAVE_PAGE_AS_PUBLISH: '/Page/SaveContentAsPublish',
    SAVE_RULES_PAGE_AS_PUBLISH: '/Page/SaveRulesContentAsPublish',
    SWITCH_PAGE: '/Page/SwitchPage',
    GET_PAGES_URLS: '/Page/GetUrls',
    GET_PAGE_HISTORY: '/Page/GetHistory',
    GET_PAGE_CONTENT_HISTORY: '/Page/GetContentHistory',

    //Popups
    ADD_POPUP: '/Popup/CreatePopup',
    EDIT_POPUP: '/Popup/EditPopup',
    GET_TEMPLATE_POPUP: '/Popup/GetTemplate',
    EDIT_TEMPLATE_POPUP: '/Popup/EditTemplate',
    SWITCH_POPUP: '/Popup/SwitchPopup',
    DELETE_POPUP: '/Popup/DeletePopup',
    GET_POPUPS: '/Popup/GetPopups',
    GET_DEFAULT_POPUPS: '/Popup/GetDefaultPopups',
    GET_POPUP_CONTENTS: '/Popup/GetContentsInfo',
    GET_POPUP_CONTENT: '/Popup/GetContent',
    SAVE_POPUP_CONTENT: '/Popup/EditContent',
    GET_POPUP_SETTINGS: '/Popup/GetSettings',
    EDIT_POPUP_SETTINGS: '/Popup/EditSettings',
    CHECK_POPUP_NAME: '/Popup/CheckPopupName',
    CHECK_POPUP_ALIAS_NAME: '/Popup/CheckPopupAlias',

    //Bg images
    GET_BACKGROUND_IMAGES: '/Page/GetBackgrounds',
    GET_BACKGROUND_IMAGE_PAGES: '/Page/GetBackgroundImagePages',
    ADD_BACKGROUND_IMAGE: '/Page/AddBackgroundImage',
    EDIT_BACKGROUND_IMAGE: '/Page/EditBackgroundImage',
    DELETE_BACKGROUND_IMAGE: '/Page/DeleteBackgroundImage',
    SWITCH_BACKGROUND_PAGE_STATE: '/Page/SwitchBackgroundState',

    //Screenshot
    SCREENSHOT_TAKE: '/WebScreenshot/TakeAndSave',

    //Rules
    GET_RULES_SIDEBAR: '/UICustomization/GetRulesSideBar',
    GET_RULES_BUTTONS_VISIBILITY: '/ProjectSettings/GetRulesButtonsConfig',
    GHANGE_RULES_BUTTONS_VISIBILITY: '/ProjectSettings/ChangeRulesButtonsConfig',
    ADD_SIDEBAR_MENU_ITEM: '/UICustomization/AddSideBarMenuItem',
    EDIT_SIDEBAR_MENU_ITEM: '/UICustomization/EditSideBarMenuItem',
    DELETE_SIDEBAR_MENU_ITEM: '/UICustomization/DeleteSideBarMenuItem',
    MOVE_SIDEBAR_MENU_ITEM: '/UICustomization/MoveSideBarMenuItem',
    CHANGE_RULES_CONTROL_VISIBILITY: '/UICustomization/ChangeSideBarMenuItemVisibility',

    //Business Rules
    ADD_BUSINESS_RULE: '/BusinessRule/CreateRule',
    GET_BUSINESS_RULE: '/BusinessRule/GetRule',
    GET_BUSINESS_RULES_LIST: '/BusinessRule/GetRules',
    DELETE_BUSINES_RULE: '/BusinessRule/DeleteRule',
    EDIT_BUSINES_RULE: '/BusinessRule/EditRule',
    GET_BUSINES_RULE_TRIGGERS: '/BusinessRule/GetTriggers',
    SWITCH_BUSINES_RULE: '/BusinessRule/SwitchRule',
    GET_BUSINESS_RULE_POPUPS: '/BusinessRule/GetPopupsForBusinessRule',
    CHECK_RULE_NAME: '/BusinessRule/CheckName',
    GET_POPUP_RULES: '/BusinessRule/GetPopupRules',

    // Widgets
    GET_WIDGETS: '/Widget/GetWidgets',
    GET_WIDGET: '/Widget/GetWidget',
    CREATE_WIDGET: '/Widget/CreateWidget',
    EDIT_WIDGET: '/Widget/EditWidget',
    EDIT_WIDGET_COLOR_MODE: '/Widget/EditWidgetColorMode',
    EDIT_WIDGET_OVERLAP_MODE: '/Widget/EditOverlap',
    EDIT_WIDGET_SETTING: '/Widget/EditWidgetSetting',
    DUPLICATE_WIDGETS: '/Widget/DuplicateWidget',
    DELETE_WIDGET: '/Widget/DeleteWidget',
    GET_WIDGET_TEMPLATES: '/Widget/GetWidgetTemplates',
    CHECK_WIDGET_NAME: '/Widget/CheckName',
    RENAME_WIDGET: '/Widget/RenameWidget',
    ADD_WIDGET_TRANSLATION_ENTRY: '/Widget/TranslationEntry',
    ADD_WIDGET_TRANSLATION: '/Widget/Translation',
    GET_WIDGET_TRANSLATION_ENTRY: '/Widget/TranslationEntry',

    //Announcments
    GET_ANNOUNCMENT_SETTINGS: '/Announcement/GetAnnouncementSetting',
    SET_ANNOUNCMENT_SETTINGS: '/Announcement/EditAnnouncementSetting',
    ADD_ANNOUNCMENT: '/Announcement/CreateAnnouncement',
    DELETE_ANNOUNCMENT: '/Announcement/DeleteAnnouncement',
    EDIT_ANNOUNCMENT: '/Announcement/EditAnnouncement',
    EDIT_ANNOUNCMENT_CONTENT: '/Announcement/EditAnnouncementContent',
    CHANGE_ANNOUNCMENT_VISIBILITY: '/Announcement/ChangeAnnouncementVisibility',
    CHANGE_ANNOUNCMENT_SECTION_VISIBILITY: '/Announcement/ChangeSectionVisibility',
    CHECK_ANNOUNCMENT_NAME: '/Announcement/CheckName',
    GET_ANNOUNCMENTS: '/Announcement/GetAnnouncements',
    MOVE_ANNOUNCMENTS: '/Announcement/MoveAnnouncement',

    //Mobile App
    GET_MOBILE_APP_DATA: '/Announcement/GetAnnouncements',
    EDIT_MOBILE_APP_DATA: '/Announcement/EditAnnouncement',

    //DevTools
    CLEAR_WEBSITE_CACHE_DNS: '/Cache/FlushDNSCache',
    CLEAR_WEBSITE_CACHE_DATABASE: '/Cache/FlushDatabaseCache',

    // Story management
    GET_STORY_BLOCK: '/BuilderAdmin/StoryManagement',
    ADD_STORY_BLOCK_SECTION: '/BuilderAdmin/StoryManagement/Block',
    CHANGE_STORY_BLOCK_SECTION_VISIBILITY: '/BuilderAdmin/StoryManagement/State',
    CHANGE_STORY_BLOCKS_SECTION_VISIBILITY: '/BuilderAdmin/StoryManagement/Block/Status',
    CHANGE_STORY_IMAGE_VISIBILITY: '/BuilderAdmin/StoryManagement/Story/Status',
    CHANGE_STORY_BLOCKS_SECTION_SESSION_VISIBILITY: '/BuilderAdmin/StoryManagement/Visibility',
    GET_STORY_BLOCK_SETTINGS: '/BuilderAdmin/StoryManagement/Settings',
    ADD_STORY_BLOCK: '/BuilderAdmin/StoryManagement/Story',

    // SEO Rules
    GET_SEO_RULES: '/BuilderAdmin/SEORules/GetSEORules',
    GET_SEO_RULE_CONTENTS: '/BuilderAdmin/SEORules/GetSEORuleContents',
    ADD_SEO_RULE: '/BuilderAdmin/SEORules/AddSEORule',
    EDIT_SEO_RULE: '/BuilderAdmin/SEORules/EditSEORule',
    DELETE_SEO_RULE: '/BuilderAdmin/SEORules/DeleteSEORule',
    CHECK_SEO_RULE_NAME: '/BuilderAdmin/SEORules/CheckSEORuleName',
    ADD_SEO_RULE_META_CONTENT: '/BuilderAdmin/SEORules/AddSEORuleMetaContent',
    EDIT_SEO_RULE_META_CONTENT: '/BuilderAdmin/SEORules/EditSEORuleMetaContent',
    ADD_SEO_RULE_BLOCK_CONTENT: '/BuilderAdmin/SEORules/AddSEORuleBlockContent',
    EDIT_SEO_RULE_BLOCK_CONTENT: '/BuilderAdmin/SEORules/EditSEORuleBlockContent',
    DELETE_SEO_RULE_BLOCK_CONTENT: '/BuilderAdmin/SEORules/DeleteSEORuleBlockContent',
    CHANGE_SEO_RULE_SECTION_STATE: '/BuilderAdmin/SEORules/ChangeSEORulesSectionState',
    CHANGE_SEO_RULE_STATE: '/BuilderAdmin/SEORules/SEORulesState',
    GET_SEO_RULES_SECTION_STATE: '/BuilderAdmin/SEORules/GetSEORulesSectionState',
    MOVE_SEO_RULE:'/BuilderAdmin/SEORules/MoveRule'

};